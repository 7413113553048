<template>
  <List el="xsm" style="max-width: 1200px">
    <List el="column-between xsm">
      <Text el="h2">Users</Text>
      <!-- <Btn
        @click="to('/dashboard/recordings/upload')"
        v-if="$store.getters.isAdmin"
        >Create</Btn
      > -->
    </List>

    <!-- <List el="column-between xsm" style="align-items: center">
      <div el="tabs-underline" style="user-select: none">
        <div
          :class="{ active: tab == 'calendar' }"
          @click="setSection('calendar')"
        >
          calendar
        </div>
        <div
          :class="{ active: tab == 'schedule' }"
          @click="setSection('schedule')"
        >
          schedule
        </div>
      </div>
    </List> -->
    <UserManagement v-if="isAdmin" />
    <Text v-else>You must be an administrator to view this page.</Text>
    
  </List>
</template>
<script>
import router from "@/router";
// let filter = require("lodash/filter");
// let lowerCase = require("lodash/lowerCase");
// let includes = require("lodash/includes");
import UserManagement from "../components/UserManagement.vue";

// let user = {
//   accountType: 'client',
//   createdOn:,
  
//   email:'test@email.com',
//   phone:'45645645',
//   lastSignIn:,

// }


export default {
  components: {
    // FullCalendar,
    UserManagement
  },
  data() {
    return {
      tab: 'calendar'
    };
  },
  methods: {
    formatDate(dateString) {
      const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const d = new Date(`${dateString} 00:00:00`);
      return `${dateString}, ${days[d.getDay()]}`;
    },
    setSection(name) {
      this.tab = name;
    },
    to(path) {
      this.$store.commit("collapseSection", false);
      router.push(path);
    },
  },
  computed: {
    user(){
      return this.$store.state.main.user;
    },
    isAdmin(){
      return this.user.permissions.includes('admin');
    }
  },
  mounted() {
    let pathArr = this.$route.path.split("/");
    let endPath = pathArr.pop();
    if (endPath == "upload") {
      this.$store.commit("collapseSection", false);
    } else {
      this.$store.commit("collapseSection", true);
    }
    // this.$store.commit("recordings/loadList");
  },
};
</script>
<style lang="scss" scoped>
tr.active {
  /* box-shadow: 0 0 0 1px var(--color-sky); */
  outline: 1px solid var(--color-sky);
  outline-offset: -1px;
  background-color: #f4f8ff !important;
}
tr.active td {
  color: var(--color-sky);
}
</style>
